import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import axios from "axios";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {
  Container,
  Button,
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  Link,
  Snackbar,
  Alert,
  Backdrop,
  CircularProgress,
  Modal,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import FeedbackButton from "../Components/FeedbackButton";
import { useAtom } from "jotai";
import {
  loadingPageAtom,
  tableOfContentsAtom,
  mainColorAtom,
  accountNameAtom,
  secondaryColorAtom,
  saveSuccessAtom,
  allowedToEditAtom,
} from "../Atoms";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import html2pdf from "html2pdf.js";
import "./ContentEditorPage.css";
import OverviewContents from "../Layout/OverviewContents";
import { Tooltip } from '@mui/material';

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline"],
    [{ color: [] }, { background: [] }],
    ["link", "image", "video"],
    ["blockquote", "code-block"],
    ["clean"],
    ["strike"],
  ],
};

const styles = {
  savetopdf: {
    color: "gray",
    fontSize: "12px",
  },
};

const ContentEditorPage = ({ title }) => {
  const [loadingPage, setLoadingPage] = useAtom(loadingPageAtom);
  const [openDialog, setOpenDialog] = useState(false);
  const [accountName] = useAtom(accountNameAtom);
  const [allowedToEdit] = useAtom(allowedToEditAtom);

  const [accName, setAccName] = useState(null);

  const [mainColor] = useAtom(mainColorAtom);
  const [secondaryColor] = useAtom(secondaryColorAtom);

  const [initialContent, setInitialContent] = useState("");
  const [modifiedContent, setModifiedContent] = useState("");
  //Actual content
  const [content, setContent] = useState("");
  const [author, setAuthor] = useState("");
  const [lastEdited, setLastEdited] = useState(null);

  const [formattedDate, setFormattedDate] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [tableOfContents, setTableOfContents] = useAtom(tableOfContentsAtom);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [saveSuccess, setSaveSuccess] = useAtom(saveSuccessAtom);
  const [, setSaveError] = useState(false);

  const [expanded, setExpanded] = useState(false);


  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  
useEffect(() => {
  if (accountName){
    setAccName(accountName);
  }
}, [accountName]);


  const topRef = useRef(null);

  const scrollToTop = () => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleScrollToSection = () => {
      const sectionId = window.location.hash.substring(1);
      if (sectionId) {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    };
  
    // Wait for the content to render before scrolling
    const timeoutId = setTimeout(handleScrollToSection, 500);
  
    // Attach the event listener to handle scrolling when the URL changes
    window.addEventListener("hashchange", handleScrollToSection);
  
    // Clean up the event listener and timeout
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("hashchange", handleScrollToSection);
    };
  }, [content]);
  

  const handleSaveToPDF = () => {
    // Create an element with the content you want to save as PDF
    const pdfContainer = document.createElement("div");
    pdfContainer.innerHTML = `
          <h1>${title}</h1>
          <h2>Inhoudsopgave</h2>
          <ul>
            ${tableOfContents
              .map((item) => `<li><a href="#${item.id}">${item.title}</a></li>`)
              .join("")}
          </ul>
          ${modifiedContent}
        `;

    // Generate the PDF with the provided options
    var opt = {
      margin: [15, 15],
      filename: `${title}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true },
      jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };

    html2pdf(pdfContainer, opt);
  };

  useEffect(() => {
    const handleContentFetch = async () => {
      try {
        setLoadingPage(true);
        const functionCodeContent = process.env.REACT_APP_CONTENT_CODE;
        const apiUrl = `https://docucontentfunction.azurewebsites.net/api/ContentFunction?title=${title}&code=${functionCodeContent}`;
        const response = await axios.get(apiUrl);

        if (response.status === 200) {
          const contentData = response.data;
          setContent(contentData[0].contentText);
          setAuthor(contentData[0].author);
          setLastEdited(contentData[0].lastEdited);
        }
      } catch (error) {
        console.error("Failed to fetch content", error);
      } finally {
        setLoadingPage(false);
      }
    };

    handleContentFetch();
  }, [title, saveSuccess]);

  const handleChange = (value) => {
    setContent(value);
  };

  useEffect(() => {
    if (editMode) {
      setInitialContent(content);
    }
  }, [editMode]);

  const handleCancel = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = (discardChanges) => {
    setOpenDialog(false);

    if (discardChanges) {
      setContent(initialContent);
      setEditMode(false);
    }
  };

  useEffect(() => {
    if (content) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, "text/html");

      const headingElements = Array.from(doc.querySelectorAll("h1, h2"));

      // Set section IDs first
      headingElements.forEach((heading, index) => {
        const sectionId = `section${index + 1}`;
        heading.id = sectionId;
      });

      // Calculate modifiedContent after IDs are set
      const newModifiedContent = addSectionIdsAndShadowToImages(content);
      setModifiedContent(newModifiedContent);
    }
  }, [content]);

  const addSectionIdsAndShadowToImages = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    const imageElements = Array.from(doc.querySelectorAll("img"));
    const headingElements = Array.from(doc.querySelectorAll("h1, h2"));

    // Add section IDs to heading elements
    headingElements.forEach((heading, index) => {
      const sectionId = `section${index + 1}`;
      heading.id = sectionId;
      //make all h1 secondary color, h1 primary color
      heading.style.color =
        heading.tagName.toLowerCase() === "h1" ? secondaryColor : mainColor;
    });

    const updatedTOC = headingElements.map((heading) => ({
      id: heading.id,
      title: heading.textContent.trim(),
      level: heading.tagName.toLowerCase(),
    }));

    setTableOfContents(updatedTOC);

    // Add shadow to image elements
    imageElements.forEach((img) => {
      img.style.boxShadow = "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px";
      img.style.maxWidth = "100%";
    });

    return doc.documentElement.outerHTML;
  };

  useEffect(() => {
    if (lastEdited) {
      const lastEditedHelp = new Date(lastEdited);
      const formattedDate = lastEditedHelp.toLocaleDateString("nl-NL", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });

      setFormattedDate(formattedDate);
    }
  }, [lastEdited]);

  const handleContentUpload = async () => {
    try {
      const contentData = {
        Title: title,
        ContentText: content,
        Author: accName,
        TableOfContents: JSON.stringify(tableOfContents),
      };
      const functionContentUploadCode =
        process.env.REACT_APP_CONTENT_UPLOAD_CODE;
      const apiUrl = `https://docucontentfunction.azurewebsites.net/api/ContentFunction?code=${functionContentUploadCode}`;
      const response = await axios.post(apiUrl, JSON.stringify(contentData), {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setSaveSuccess(true);
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 3000); // Close Snackbar after 3 seconds
        setEditMode(false);
        // You can display a success message here if needed
      } else {
        console.error("Failed to save content");
        setSaveError(true);
        setShowSnackbar(true);
        // You can display an error message here if needed
      }
    } catch (error) {
      console.error("Error while saving content:", error);
      // You can display an error message here if needed
    }
  };

  return (
    <div className="editor-page">
      <div className="container">
        <div className="scroll-to-top">
          <Button
            variant="contained"
            color="primary"
            onClick={scrollToTop}
            sx={{
              backgroundColor: secondaryColor,
            }}
          >
            Scroll naar boven
          </Button>
        </div>
        <Container>
          <div ref={topRef} />

          {!editMode && (
            <div className="editor-container">
              <div>
                <Typography
                  variant="h4"
                  component="h1"
                  className="article-header"
                >
                  {title}
                </Typography>
                <Typography variant="body2" className="last-edited">
                  {`Laatst bewerkt: ${formattedDate}`}
                </Typography>
                <Typography variant="body2" className="author">
                  {`Auteur: ${author}`}
                </Typography>
                <FeedbackButton pageName={title} />

                <Typography variant="h6" className="table-of-contents">
                  In dit artikel
                </Typography>
              </div>
              <div>
                <Box sx={{ textAlign: "left" }}>
                  <List style={{ fontSize: "14px" }}>
                    {tableOfContents
                      .slice(0, expanded ? tableOfContents.length : 4)
                      .map((item) => (
                        <ListItem
                          key={item.id}
                          sx={{
                            pl: item.level === "h2" ? "30px" : "0px",
                            fontWeight: item.level === "h1" ? "bold" : "normal",
                          }}
                        >
                          <Link
                            href={`#${item.id}`}
                            sx={{ textDecoration: "none" }}
                          >
                            {item.title}
                          </Link>
                        </ListItem>
                      ))}
                  </List>
                  {tableOfContents.length > 4 && (
                    <Button
                      sx={{
                        fontSize: "12px",
                        textAlign: "left",
                        marginLeft: "10px",
                        color: secondaryColor,
                        borderColor: secondaryColor,
                      }}
                      onClick={toggleExpanded}
                    >
                      {expanded ? "Minder weergeven" : "Meer weergeven"}
                    </Button>
                  )}
                </Box>

                <IconButton
                  color="primary"
                  onClick={handleSaveToPDF}
                  size="small"
                >
                  <Typography variant="body2" sx={styles.savetopdf}>
                    Opslaan als PDF
                  </Typography>
                </IconButton>
              </div>
            </div>
          )}
          <div className="content-container">
            {editMode ? (
              <div>
                <div className="save-icon">
                  <IconButton
                    color="primary"
                    onClick={handleContentUpload}
                    size="small"
                  >
                    <SaveIcon />
                  </IconButton>
                </div>
                <div className="cancel-icon">
                  <IconButton
                    color="secondary"
                    onClick={handleCancel}
                    size="small"
                  >
                    <CancelIcon />
                  </IconButton>
                </div>
              </div>
            ) : (<>
              {allowedToEdit && (
                <div className="edit-icon">
                  <Tooltip title="Edit">
                    <IconButton
                      color="primary"
                      onClick={() => setEditMode(true)}
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
              </>)
            }
            <div className="reactquill-editor">
              {editMode ? (
                <ReactQuill
                  value={content}
                  onChange={handleChange}
                  modules={modules}
                  style={{
                    height: "80vh",
                    width: "90vh",
                    position: "relative",
                    padding: "30px",
                  }}
                />
              ) : (
                <div>
                  <div
                    dangerouslySetInnerHTML={{ __html: modifiedContent }}
                    style={{
                      minHeight: "200px",
                      textAlign: "left",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          {showSnackbar && (
            <Snackbar
              open={showSnackbar}
              autoHideDuration={3000} // Hide after 3 seconds
              onClose={() => setShowSnackbar(false)}
            >
              <Alert
                onClose={() => setShowSnackbar(false)}
                severity={saveSuccess ? "success" : "error"}
              >
                {saveSuccess
                  ? "Opslaan is gelukt!"
                  : "Er ging iets mis met opslaan :("}
              </Alert>
            </Snackbar>
          )}

          <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
            <DialogTitle>Niet opslaan?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Weet je zeker dat je de veranderingen niet wil opslaan?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleDialogClose(false)} color="primary">
                Annuleer
              </Button>
              <Button onClick={() => handleDialogClose(true)} color="primary">
                Niet opslaan
              </Button>
            </DialogActions>
          </Dialog>
          <Modal
            open={loadingPage}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          </Modal>
        </Container>
      </div>
      <div className="overview-content">
        <OverviewContents />
      </div>
    </div>
  );
};

export default ContentEditorPage;
