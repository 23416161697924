import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, IconButton, Dialog } from '@mui/material';
import ChatBotIcon from '../Icons/crisoscar.png';
import { useAtom } from 'jotai';
import { mainColorAtom, secondaryColorAtom, messageHistoryAtom, accessTokenAtom } from '../Atoms';
import { useMsal } from '@azure/msal-react';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import './Chat2.css';

const Oscar = ({ questions = [] }) => {
  const [inputMessage, setInputMessage] = useState('');
  const [messageHistory, setMessageHistory] = useAtom(messageHistoryAtom);
  const [isProcessing, setIsProcessing] = useState(false);
  const [mainColor] = useAtom(mainColorAtom);
  const [secondaryColor] = useAtom(secondaryColorAtom);
  const fullName = '';
  const words = fullName.split(' ');
  const [isDynamicsQuery, setIsDynamicsQuery] = useState(false);
  const [accessToken] = useAtom(accessTokenAtom);
  const [customSystemMessage, setCustomSystemMessage] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const chatHistoryRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom of the chat history when messageHistory changes
    chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
  }, [messageHistory]);

  const handleCopyMessage = (content) => {
    navigator.clipboard.writeText(content);
  };

  const handleClearChat = () => {
    const date = new Date();
    const hour = date.getHours();
    let groet = (hour < 12) ? 'Goedemorgen' : (hour < 18) ? 'Goedemiddag' : 'Goedenavond';
    setMessageHistory([{ role: 'system', content: `${groet} ${firstName}, hoe kan ik je helpen?` }]);
  };

  const copyFormattedText = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const formattedHTML = doc.body.innerHTML || "";
    
    // Maak een tijdelijk element om de opgemaakte HTML-inhoud vast te houden
    const tempElement = document.createElement('div');
    tempElement.innerHTML = formattedHTML;
  
    // Voeg het tijdelijke element toe aan de body (anders worden scripts mogelijk niet uitgevoerd)
    document.body.appendChild(tempElement);
  
    // Selecteer de inhoud van het tijdelijke element
    const range = document.createRange();
    range.selectNode(tempElement);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  
    // Kopieer de geselecteerde inhoud naar het klembord
    document.execCommand('copy');
  
    // Verwijder het tijdelijke element
    document.body.removeChild(tempElement);
  };
  

  // Extract the first and middle names (if present)
  const firstName = words.slice(0, words.length - 1).join(' ');

  useEffect(() => {
    if (messageHistory.length === 0) {
      const date = new Date();
      const hour = date.getHours();
      let groet = (hour < 12) ? 'Goedemorgen' : (hour < 18) ? 'Goedemiddag' : 'Goedenavond';
      setMessageHistory([{ role: 'system', content: `${groet} ${firstName}, hoe kan ik je helpen?` }]);
    }
  }, []);

  const handleInputChange = (event) => {
    setInputMessage(event.target.value);
  };

  const handleDynamicsQuery = async (message) => {
    const url = process.env.REACT_APP_AIMODEL_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY_CHATBOT;

  // Add message to history
  const updatedMessageHistory = [...messageHistory, { role: 'user', content: message }];
  setMessageHistory(updatedMessageHistory);
  setIsProcessing(true);
  const updatedOutputMessage = updatedMessageHistory.concat({ role: 'system', content: 'Aan het zoeken in Dynamics..' });
  setMessageHistory(updatedOutputMessage);
  setIsProcessing(false);
  setInputMessage('');
  inputRef.current.focus();

    const headers = {
      'Content-Type': 'application/json',
      'api-key': apiKey,
    };
  
    // Check if the message contains dangerous words
    if (containsDangerousWords(message)) {
      handleDangerousWords(message);
      return;
    }
  
    const Message = message.toLowerCase();
    const requestVraag = generateRequestMessage(Message);
  
    const data = {
      messages: [
        { role: 'system', content: 'Je krijgt de vraag om een Dynamics web api url te genereren. Zorg ervoor dat de namen van de kolommen kloppen! Verzin geen onzin namen. Het is allemaal standaard in Dynamics. Het kolom van accounts heet \'accounts\', van emails heet \'emails\', van projecten heet \'msdyn_projects\' (en een projectnaam is msdyn_subject), van contacten heet contacts' },
        { role: 'user', content: requestVraag },
      ],
      max_tokens: 3500,
      temperature: 0.7,
      frequency_penalty: 0,
      presence_penalty: 0,
      top_p: 0.95,
      stop: null,
    };
  
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    });
  
    const ans = await handleResponse(response, message);
  
    if (ans) {
      // Send the query to Dynamics
      await sendQueryToDynamics(ans, message);
    }
  };
  
  const containsDangerousWords = (message) => {
    const Message = message.toLowerCase();
    return (
      Message.includes('delete') ||
      Message.includes('truncate') ||
      Message.includes('drop') ||
      Message.includes('remove') ||
      Message.includes('verwijder') ||
      Message.includes('verwijderen') ||
      Message.includes('weghalen') ||
      Message.includes('alter') ||
      Message.includes('set') ||
      Message.includes('update') ||
      Message.includes('create')
    );
  };
  
  const handleDangerousWords = (message) => {
    const updatedMessageHistory = [...messageHistory, { role: 'user', content: message }];
    setMessageHistory(updatedMessageHistory);
    setIsProcessing(true);
    const updatedOutputMessage = updatedMessageHistory.concat({ role: 'system', content: 'Het bericht was onveilig, probeer het opnieuw.' });
    setMessageHistory(updatedOutputMessage);
    setIsProcessing(false);
    setInputMessage('');
    inputRef.current.focus();
  };
  
  const generateRequestMessage = (message) => {
    return `Geef een web api url van de volgende query: ${message}. https://rfo.api.crm4.dynamics.com/api/data/v9.2.23122.190/
      Zeg niks anders dan het url. Zorg ervoor dat de query niet meer dan 20 rows en 5 kolommen heeft, anders kan het niet worden weergegeven! Als een naam van een persoon in het bericht staat, gebruik dan include`;
  };
  
  const handleResponse = async (response, message) => {
    const json = await response.json();
    const ans = json.choices[0].message.content;
  
    // Check if the answer contains dangerous words
    if (containsDangerousWords(ans)) {
      handleDangerousWords(message);
      return;
    }

    return ans;
  };
  
  const sendQueryToDynamics = async (urlDynamics, message) => {
    const headersDynamics = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    };
  
    try {
      const response = await fetch(urlDynamics, {
        method: 'GET',
        headers: headersDynamics,
      });
  
      const json = await response.json();
      const table = createHtmlTable(json);
  
      // Add message to history
      const updatedMessageHistory = [...messageHistory, { role: 'user', content: message }];
      setMessageHistory(updatedMessageHistory);
      setIsProcessing(true);
      const updatedOutputMessage = updatedMessageHistory.concat({ role: 'system', content: table });
      setMessageHistory(updatedOutputMessage);
      setIsProcessing(false);
      setInputMessage('');
      inputRef.current.focus();
    } catch (error) {
      const updatedMessageHistory = [...messageHistory, { role: 'user', content: message }];
      setMessageHistory(updatedMessageHistory);
      setIsProcessing(true);
      const updatedOutputMessage = updatedMessageHistory.concat({ role: 'system', content: 'Er ging iets mis, probeer het opnieuw.'});
      setMessageHistory(updatedOutputMessage);
      setIsProcessing(false);
      setInputMessage('');
      inputRef.current.focus();
      console.log(error, 'error');
    }
  };
  
  function createHtmlTable(jsonData) {
    const columns = Object.keys(jsonData.value[0]);
  
    // Drop unwanted columns
    const unwantedColumns = ['@odata.etag'];
    const linkColumns = columns.filter(column => !unwantedColumns.includes(column) && column.toLowerCase().includes('id'));
    const displayColumns = columns.filter(column => !unwantedColumns.includes(column) && !column.toLowerCase().includes('id'));
  
    // Create the table header
    const tableHeader = `<thead><tr>${displayColumns.map(column => `<th>${column}</th>`).join('')}</tr></thead>`;
  
    // Create the table rows with a link for each item
    const tableRows = jsonData.value.map(item => {
      const dynamicsLink = getDynamicsLink(item, linkColumns);
      const rowCells = displayColumns
        .map(column => `<td>${item[column]}</td>`)
        .join('');
      return `<tr onclick="window.open('${dynamicsLink}', '_blank')">${rowCells}</tr>`;
    }).join('');
  
    // Combine the header and rows to create the HTML table
    const htmlTableWithoutId = `<table class="styled-table">${tableHeader}<tbody>${tableRows}</tbody></table>`;
  
    return htmlTableWithoutId;
  }
  
  
  
  
  function getDynamicsLink(item, columns) {
    // Search for the column that includes the word 'id'
    const idColumn = columns.find(column => column.toLowerCase().includes('id'));
  
    if (idColumn) {
      const itemId = item[idColumn];
      const entityName = idColumn.replace('id', ''); // Extract entity name from the id column
  
      // If entityName is available, construct the link
      if (entityName) {
        return `https://rfo.crm4.dynamics.com/main.aspx?pagetype=entityrecord&etn=${entityName}&id=${itemId}`;
      }
    }
  
    return '#';
  }


  const callOpenAPI = async (message, messageHistory) => {
    const url = process.env.REACT_APP_AIMODEL_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY_CHATBOT;

    const sysMsg = `Je bent een AI-assistent die mensen helpt bij het vinden van informatie. Je naam is OSCAR, het Open System Chatbot Assistant Rembrandt. Gebruik altijd html tags in je antwoorden, vergeet dat niet! Gebruik <html>, <head>, <title>, <body>, <h1> tm <h6>, <p>, <i>, <a>, <label>, <tr>, <td>, <canvas>, <b>, <img> <ul>, <ol>, <li>, <div>, <span>,  <link> (open in nieuw tabblad), <meta> en alle andere html tags. Maak mooie opmaak en zorg ervoor dat je antwoorden duidelijk zijn. Hou je antwoorden robuust, geef alleen de inhoud van het antwoord, geen aanleiding en afsluiting, je hebt geen formaliteit nodig. Gebruik geen aanhalingstekens buiten de html tags (dus geen ''' of iets dergelijks.).`;

    const headers = {
      'Content-Type': 'application/json',
      'api-key': apiKey,
    };

    const fullSysMsg = `${sysMsg} ${customSystemMessage}`;

    const data = {
      messages: [
        {
          role: 'system',
          content: fullSysMsg,
        },

        ...messageHistory, // Include previous messages
        { role: 'user', content: message },
      ],
      max_tokens: 3500,
      temperature: 0.7,
      frequency_penalty: 0,
      presence_penalty: 0,
      top_p: 0.95,
      stop: null,
    };

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    });

    const json = await response.json();
    return json.choices[0].message.content;
  };

  const handleSendMessage = async (message) => {

    if (message.trim() === '') {
      return; // Don't send empty messages
    }

    if(isDynamicsQuery) {
        handleDynamicsQuery(message);
        return;
    }

    const updatedMessageHistory = [...messageHistory, { role: 'user', content: message }];
    setMessageHistory(updatedMessageHistory);
    setIsProcessing(true); // Set processing state

    try {
      const response = await callOpenAPI(message, updatedMessageHistory);
      const updatedMessage = response || 'Oops! Er ging iets mis.';
      const updatedOutputMessage = updatedMessageHistory.concat({ role: 'system', content: updatedMessage });
      setMessageHistory(updatedOutputMessage);
    } catch (error) {
      console.error('Error calling OpenAI API:', error);
      const errorMessage = 'Oops! Er ging iets mis.';
      const updatedOutputMessage = updatedMessageHistory.concat({ role: 'system', content: errorMessage });
      setMessageHistory(updatedOutputMessage);
    }

    setIsProcessing(false); // Reset processing state
    setInputMessage(''); // Clear the input field after sending the message
    inputRef.current.focus();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        event.preventDefault();
        setInputMessage((prevValue) => prevValue + '\n');
      } else {
        event.preventDefault();
        handleSendMessage(inputMessage);
        setInputMessage('');
      }
    }
  };


  const parseFormattedText = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    return doc.body.innerHTML;
  };

  return (
    <>
      <div className="chat-container">
        <div className="chat-history" ref={chatHistoryRef}>
          {messageHistory.map((message, index) => (
            <div
              key={index}
              className={`message ${message.role === 'user' ? 'user-message' : 'api-message'
                }`}
            >
              {message.role === 'user' ? (
                <>
                  {message.content}
                  <IconButton
                    color="primary"
                    onClick={() => handleCopyMessage(message.content)}
                  >
                    <FileCopyOutlinedIcon style={{ fontSize: 15 }} />
                  </IconButton>
                </>
              ) : (
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={ChatBotIcon}
                      alt="ChatBot Icon"
                      className="chatbot-icon"
                      style={{ width: '40px', height: '40px', marginRight: '8px' }}
                    />
                    <div dangerouslySetInnerHTML={{ __html: parseFormattedText(message.content) }} />
                  </div>
                  <IconButton
                    color="primary"
                    onClick={() => copyFormattedText(message.content)}
                  >
                    <FileCopyOutlinedIcon style={{ fontSize: 15 }} />
                  </IconButton>
                </>
              )}
            </div>
          ))}
          {isProcessing && (
            <div className="message api-message">OSCAR denkt na...</div>
          )}
        </div>
        <div className="chat-buttons">
          {questions.map((question, index) => (
            <Button
              key={index}
              variant="contained"
              color="primary"
              onClick={() => handleSendMessage(question)}
              disabled={isProcessing}
              sx={{ backgroundColor: secondaryColor, '&:hover': { backgroundColor: mainColor } }}
            >
              {question}
            </Button>
          ))}
        </div>
        <div className="chat-input">
          <IconButton
            onClick={handleClearChat}
            disabled={isProcessing}
            title='Start New Chat'
            style={{ marginBottom: '15px', marginRight: '10px' }}
          >
            <DeleteIcon />
          </IconButton>
          <TextField
            value={inputMessage}
            placeholder="Typ hier je vraag..."
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            fullWidth
            multiline
            variant="outlined"
            margin="normal"
            disabled={isProcessing}
            inputProps={{ style: { borderRadius: '5px' } }}
            inputRef={inputRef}
            style={{ maxHeight: '150px', overflowY: 'auto', paddingTop: '20px' }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSendMessage(inputMessage)}
            disabled={isProcessing}
            sx={{ backgroundColor: secondaryColor, '&:hover': { backgroundColor: mainColor }, marginBottom: '20px', marginLeft: '10px'}}
          >
            Verstuur
          </Button>
          
        </div>
        <IconButton
          onClick={handleDialogOpen}
        >
          Custom System Message
        </IconButton>

        <Dialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          fullWidth
          maxWidth="sm"
        >
          <div style={{ padding: '20px' }}>
            <h2>Custom System Message</h2>
            <TextField
              value={customSystemMessage}
              onChange={(event) => setCustomSystemMessage(event.target.value)}
              fullWidth
              multiline
              variant="outlined"
              margin="normal"
              inputProps={{ style: { borderRadius: '5px' } }}
              style={{ marginBottom: '20px' }}
            />
          </div>
        </Dialog>

        {/* <div >
          <input
            type="checkbox"
            checked={isDynamicsQuery}
            onChange={() => setIsDynamicsQuery(!isDynamicsQuery)}
          />
          <label>Zoek in Dynamics</label>
        </div> */}
          
      </div>

    </>
  );
};

export default Oscar;