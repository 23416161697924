import PowerpointIcon from '../Icons/powerpoint_icon.png';
import ExcelIcon from '../Icons/excel_icon.png';
import OutlookIcon from '../Icons/outlook_icon.png';
import DynamicsIcon from '../Icons/dynamics_icon.png';
import SharePointIcon from '../Icons/sharepoint_icon.png';
import RMAIcon from '../Icons/rmalogo2.png';
import RaboIcon from '../Icons/rabo_icon.png';
import OneNoteIcon from '../Icons/OneNoteIcon.png';

const iconWH = '35px';


const UniformeWerkwijzeData = [
    { title: 'Outlook Web', text: 'De nieuwe Outlook Add in voor Outlook Web', icon: <img src={OutlookIcon} alt="Outlook Web Icon" style={{ width: iconWH, height: iconWH }} />, link: '/Outlook-Web-Uniforme-Werkwijze' },
    { title: 'Excel', text: 'Lees meer over de Excel Add-in', icon: <img src={ExcelIcon} alt="Excel Icon" style={{ width: iconWH, height: iconWH }} />, link: '/Excel-Uniforme-Werkwijze' },
    { title: 'PowerPoint', text: 'Leer meer over de PowerPoint Add-in', icon: <img src={PowerpointIcon} alt="PowerPoint Icon" style={{ width: iconWH, height: iconWH }} />, link: '/PowerPoint-Uniforme-Werkwijze' },
    { title: 'Dynamics', text: 'Bekijk hoe Dynamics gebruikt wordt binnen RMA', icon: <img src={DynamicsIcon} alt="Dynamics Icon" style={{ width: iconWH, height: iconWH }} />, link: '/dynamics' },
    { title: 'SharePoint', text: 'Bekijk hoe Sharepoint gebruikt wordt binnen RMA', icon: <img src={SharePointIcon} alt="SharePoint Icon" style={{ width: iconWH, height: iconWH }} />, link: '/sharepoint' },
    { title: 'Administratie', text: 'Lees meer over de administratie van RMA', icon: <img src={RMAIcon} alt="Administratie Icon" style={{ width: iconWH, height: iconWH }} />, link: '/administratie' },
    { title: 'KYC', text: 'Leer hier meer over het KYC proces', icon: <img src={RaboIcon} alt="KYC Icon" style={{ width: iconWH, height: iconWH }} />, link: '/KYC' },
    { title: 'OneNote', text: 'Lees meer over OneNote', icon: <img src={OneNoteIcon} alt="OneNote Icon" style={{ width: iconWH, height: iconWH }} />, link: '/OneNote-UWW' },
    { title: 'Marketing', text: 'Lees meer over de marketing van RMA', icon: <img src={RMAIcon} alt="Marketing Icon" style={{ width: iconWH, height: iconWH }} />, link: '/marketing-Uniforme-Werkwijze' },
];

export default UniformeWerkwijzeData;

// {/*OneNote*/},
// {/*CM Sign*/},
// {/*Overig*/},